<template>
    <div>
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
      <b-row class="match-height">
        <b-col
          lg="12"
          md="12"
        >
       
        <b-card
          title="Edit Inventory Area User">
          <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-1"
          >
            <div class="alert-body">
              {{error_message}}
            </div>
          </b-alert>
          
          <b-form @submit="formSubmit">
            
            <b-row>
              
              <b-col md="12">
    
                <b-row>
                    <b-col md="12">
                      <b-form-group
                      label="Area"
                      class="required legend_top_space_mobile"
                      >
                      <v-select
                            v-model="form.area"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="areas"
                            placeholder="Select"
                            :clearable=false
                            v-if="checkPermission('inventory-area', 'Site Transfer')"
                          />
                      <v-select
                            v-model="form.area"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="name"
                            :options="areas"
                            placeholder="Select"
                            @input="changeArea()"
                            disabled
                            :clearable=false
                            v-else
                          />
                          
                      </b-form-group>
                    </b-col>
                
                  
                </b-row>

                
                <b-row>
                  <b-col md="3">

                    <b-form-group
                      label="Project Sites"
                      class="required"
                    >
                    
                      <v-select
                        v-model="form.site"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="site_name"
                        disabled
                        :options="sites"
                        placeholder="Select"
                        :clearable="false"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group
                      label="Area In-Charge"
                      class="required"
                    >
                
                      <v-select
                        v-model="form.user"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="full_name"
                        :options="form.users"
                        placeholder="Select"
                      />
                    </b-form-group>
                  </b-col>
                  
                  <b-col md="3">
                    <b-form-group
                      label="Site In-Charge"
                      class="required"
                    > <!-- it will be supervisor -->
                
                      <v-select
                        v-model="form.supervisor"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="full_name"
                        :options="form.supervisors"
                        placeholder="Select"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col md="3">
                    <b-form-group label="Budget" class="required" >
                      <b-form-input placeholder="$0 to $100000" v-model="form.budget" autocomplete="off" @keypress="isNumber($event)"/>
                    </b-form-group>
                  </b-col>

                </b-row>

                
                
                <b-row class="mt-2">
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="warning"
                      class="mr-1"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      @click="goBack()"
                    >
                      Cancel
                    </b-button>
                  </b-col>
              </b-row>
    
    
              </b-col>
            </b-row>
    
          </b-form>
        </b-card>
        </b-col>
      </b-row>
    
      
    </div>
    </template>
    
    <script>
    
    import {
      BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    import vSelect from 'vue-select'
    
    export default {
      components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormSelect,
        BFormSelectOption,
        BFormText,
        BInputGroupPrepend,
        BInputGroup,
        BMedia,
        BAvatar,
        BTable,
        BModal,
        VueCropper,
        BFormRadio,
        BFormTimepicker,
        VueTimepicker,
        BBreadcrumb,
        VueGoogleAutocomplete,
        vSelect
      },
      directives: {
        Ripple,
      },
      data() {
        return {
    
          
          error_message:null,
          showDismissibleAlert:false,
          sites:[],
          // users:[],
          areas: [],
          form :{
            budget: null,
            area: null,
            user:null,
            supervisor:null,
            site:null,
            _id:null,
            users: [],
            supervisors: []
          },
          
          
        }
      },
      methods : {
        isNumber($event) {
            /* let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            } */
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            const inputValue = $event.target.value;

            // Allow numbers (48-57) and the decimal point (46)
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
                $event.preventDefault();
            }

            // Allow only one decimal point
            if (keyCode === 46 && inputValue.includes('.')) {
                $event.preventDefault();
            }

            // Allow only two digits after the decimal point
            const hasDecimal = inputValue.includes('.');
            if (hasDecimal) {
                const decimalPart = inputValue.split('.')[1];
                if (decimalPart && decimalPart.length >= 2 && keyCode >= 48 && keyCode <= 57) {
                    $event.preventDefault();
                }
            }
        },
    
        formSubmit(e){
          e.preventDefault();
    
          return this.$store.dispatch(POST_API, {
               data:{
                 items:this.form
               },
               api: '/api/update-inventory-area-user'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Record Updated Successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    }).then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name: 'inventory-area-user', params: { area_id: this.form.area != null ? this.form.area._id : 'all-area' } })
                    });
                    
                }
            });
          
        },
    
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Inventory'
          },{
            to:null,
            text: 'Settings'
          },{
            to:{name:'inventory-area'},
            text: 'Area Management',
          },{
            to: { name: 'inventory-area-user', params: { area_id: this.form.area != null ? this.form.area._id : 'all-area' } },
            text: 'Inventory Area User',
          },{
            to:null,
            text: 'Edit Inventory Area User',
          }];
          return item;
        },
        goBack(){
          this.$router.push({ name: 'inventory-area-user', params: { area_id: this.form.area != null ? this.form.area._id : 'all-area' } })
        },
        
        getUsers() {
          return this.$store.dispatch(POST_API, {
            data: {
              
            },
            api: '/api/all-inventory-area-users'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                this.users = this.$store.getters.getResults.data;
                
                return this.users;
              }
            });
        },
        getInventoryArea() {
          return this.$store.dispatch(POST_API, {
            data: {
            },
            api: '/api/all-inventory-areas'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                this.areas = this.$store.getters.getResults.data;

                return this.areas;
              }
            });
        },
        changeArea() {

          this.form.sites = [];

          this.getProjectSites();
        },
        getProjectSites() {

          return this.$store.dispatch(POST_API, {
            data: {
              area_id: this.form.area != null ? this.form.area._id : null
            },
            api: '/api/all-inventory-areas-sites-by-area'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                this.sites = this.$store.getters.getResults.data;

                return this.sites;
              }
            });

        },
        getDetail() {
          return this.$store.dispatch(POST_API, {
            data: {
              id: this.$route.params.id
            },
            api: '/api/inventory-area-user-detail'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                var data = this.$store.getters.getResults.data;
                
                //console.log(data);

                this.form = {
                  budget: data.budget,
                  area: data.inventory_area,
                  user: data.user,
                  supervisor: data.supervisor,
                  site: data.sites[0],
                  _id: data._id,
                  users: this.$store.getters.getResults.om,
                  supervisors: this.$store.getters.getResults.supervisors
                };

                
                this.getProjectSites();

                return this.form;
              }
            });
        },
        
    
      },
      mounted(){
        this.getInventoryArea();
        // this.getUsers();
        this.getDetail();
      }
    }
    </script>

    <style lang="scss">
      @import '@core/scss/vue/libs/vue-select.scss';
    </style>
    